body {
  /*background-color: #e6e1e1;*/
  /*background-color: #0099cc;*/
  background-color: #ed6a46;
  /*background-color: #e75033;*/
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.clickable {
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.flex--gap {
  gap: 10px;
}

.flex--align-center {
  align-items: center;
}

.align-center {
  align-self: center;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.txt-center {
  text-align: center;
}

.txt-bold {
  font-weight: bold;
}

.txt-italic {
  font-style: italic;
}

.txt-danger {
  color: #f00;
}

.txt-large {
  font-size: 3rem;
  line-height: 1em;
}

.txt-small {
  font-size: 0.8rem;
}

.m-t {
  margin-top: 20px;
}

.m-b {
  margin-bottom: 20px;
}
