.VisitMicrositeList {

}

.VisitMicrositeList__item__name {
    /*color: #09c;*/
    font-size: 1.3rem;
    font-weight: bold;
}

.VisitMicrositeList__item__url {
    color: #999;
    font-size: 0.9rem;
}
