.Button {
    display: inline-block;
    cursor: pointer;
    font-size: 0.9rem;
    vertical-align: middle;
}

.Button--primary, .Button--secondary, .Button--tertiary, .Button--danger {
    border-radius: 30px;
    font-weight: bold;
    padding: 10px 20px;
}

.Button--primary {
    background-color: #ed6a46;
    color: #fff;
}

.Button--primary.disabled {
    background-color: #ccc;
    color: #000;
    cursor: not-allowed;
}

.Button--secondary {
    background-color: #fff;
    border: 1px solid #ed6a46;
    /*border: 1px solid #09c;*/
    color: #ed6a46;
    /*color: #09c;*/
}

.Button--link, .Button--danger-link {
    color: #444;
    display: inline;
    text-decoration: underline;
}

.Button--danger {
    background-color: #c00;
    color: #fff;
}

.Button--danger-link {
    color: #c00;
}
