.LinkList__link {
    margin: 5px 0;
}

.LinkList__link__edit {
    /*position: relative;*/
}

.LinkList__link__actions {
    display: inline-block;
    /*background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255) 30px);*/
    /*background-color: #fff;*/
    /*padding-left: 40px;*/
    /*position: absolute;*/
    /*right: 0;*/
    /*top: 0;*/
    margin-left: 10px;
    visibility: hidden;
    /*border: 1px solid #00c;*/
}

.LinkList__link__edit:hover .LinkList__link__actions {
    /*display: block;*/
    visibility: visible;
}
