@keyframes qr-loading {
    from {
        background-color: #ccc;
    }
    50% {
        background-color: #f0f0f0;
    }
    to {
        background-color: #ccc;
    }

}
.QRCodeLoading {
    background-color: #ccc;
    animation: 2s linear infinite qr-loading;
    display: flex;
    justify-content: center;
    align-items: center;
}
