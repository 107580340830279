.LoadingScreen {
    background-color: #fff;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes loading-logo {
    from {
        transform: rotate(-10deg);
    }
    to {
        transform: rotate(350deg);
    }
}

.LoadingScreen--animated img {
    animation: 30s infinite linear loading-logo;
}
