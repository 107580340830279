.Module {
    background-color: #fff;
    border: 1px solid #efefef;
    border-radius: 10px;
    box-shadow: 0 0 2px #ddd;
    margin: 0px 0 20px;
}

.ModuleBody.padded {
    padding: 10px;
}

.ModuleNotice {
    background-color: #ff0;
    color: #660;
    font-size: 0.8rem;
    padding: 5px;
}

.ModuleFooter {
    background-color: #f9f9f9;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid #efefef;
    color: #444;
    padding: 10px;
}

.ModuleHeader {
    background-color: #f9f9f9;
    border-bottom: 1px solid #efefef;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    color: #444;
    align-items: center;
    padding: 10px;
}

.ModuleHeader__title {
    font-weight: bold;
    color: #444;
}

.Module--success {
    border-color: #0c6;
}

.Module--success .ModuleHeader {
    background-color: #0c6;
}

.Module--success .ModuleHeader .ModuleHeader__title {
    color: #fff;
}
