.LinkTypeIcon {
    vertical-align: middle;
    width: 36px;
}

.small.LinkTypeIcon {
    width: 24px;
}

.large.LinkTypeIcon {
    width: 48px;
}
