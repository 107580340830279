.HamburgerButton {
    --hamburger-color: #c66;
    border-bottom: 4px solid #000;
    border-bottom-color: var(--hamburger-color, #000);
    border-top: 4px solid #000;
    border-top-color: var(--hamburger-color, #000);
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    position: relative;
    width: 30px;
}

.HamburgerButton:after {
    background-color: var(--hamburger-color, #000);
    content: ' ';
    height: 4px;
    left: 0;
    position: absolute;
    right: 0;
    top: 2px;
}
