.Checkbox {
    border: 3px solid #666;
    /*border-radius: 3px;*/
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    /*width: 30px;*/
    /*height: 30px;*/
    display: inline-block;
    line-height: 0;
    overflow: hidden;
    padding: 2px;
    position: relative;;
    vertical-align: middle;
}

.Checkbox.checked {
    border-color: #ed6a46;
    /*background-color: #ddd;*/
}

.Checkbox.disabled {
    background-color: #fff;
    border-color: #ddd;
    cursor: default;
}

.Checkbox__mark {
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-block;
    width: 20px;
    height: 20px;
    overflow: hidden;
    line-height: 0;
}

.Checkbox.checked .Checkbox__mark {
    background-color: #ed6a46;
}

.Checkbox svg {
    position: absolute;
    top: 0;
    left: 0;
}
