.PageHeader {
    background-color: #fff;
    /*border-top-left-radius: 50px;*/
    /*border-top-right-radius: 50px;*/
    border-bottom: 1px solid red;
    padding: 20px;
    position: relative;
}

/*@keyframes spinning {*/
/*    from {*/
/*        rotate: 0deg;*/
/*    }*/
/*    to {*/
/*        rotate: 360deg;*/
/*    }*/
/*}*/

.PageHeader__logo__container {
    position: relative;
}

.PageHeader__logo {
    width: 100%;
    /*animation: 10s ease infinite spinning;*/
}

.PageHeader__logo__beta {
    /*background-color: #fff;*/
    border-radius: 2px;
    /*bottom: 5%;*/
    bottom: 3%;
    color: red;
    /*box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);*/
    font-weight: bold;
    font-size: 0.6rem;
    /*padding: 10px;*/
    right: 0;
    position: absolute;
    /*transform: rotate(-35deg);*/
}

.PageHeader__nav {
    position: absolute;
    top: 10px;
    right: 15px;
}

.PageHeader__menu {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    right: 0;
    visibility: hidden;
    width: fit-content;
    white-space: nowrap;
}

.PageHeader__menu.visible {
    visibility: visible;
}

.PageHeader__logo.small {
    max-width: 125px;
}

.PageHeader__logo.normal {
    max-width: 250px;
}

.PageHeader__logo.large {
    max-width: 350px;
}
