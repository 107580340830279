.TextInput {
    padding: 7px 13px;
    border-radius: 20px;
    box-sizing: border-box;
    font-size: 1.2rem;
    outline: none;
    border: 1px solid #999;
    width: 100%;
}

.TextInput__wrap {
    position: relative;
}

.TextInput__label {
    visibility: hidden;
    position: absolute;
    top: 2px;
    left: 15px;
    font-size: .8rem;
}

.TextInput.TextInput--error {
    background-color: #ffefef;
    border-color: #c00;
}

.TextInput--focused.TextInput--placeholder .TextInput {
    padding-top: 15px;
    padding-bottom: 5px;
}
.TextInput--focused .TextInput__label {
    visibility: visible;
}
