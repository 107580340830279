.SubscribablesList {
    list-style: none;
    margin: 0;
    padding: 0;
    user-select: none;
}

.SubscribablesList__item {
    background-color: #fff;
    border: 2px dotted transparent;/* #ed6a46;*/
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    /*border-radius: 30px;*/
    cursor: pointer;
    /*padding: 5px;*/
    padding: 10px;
    margin: 10px 0;
}

.SubscribablesList__item.disabled {
    cursor: default;
}

.SubscribablesList__item.selected {
    border-color: #09c;
    border-style: solid;
    /*background-color: #09c;*/
    /*border-style: solid;*/
    /*color: #fff;*/
}

.SubscribablesList__item__label {
    font-weight: bold;
    padding-left: 5px;
}
