input.EditableText, textarea.EditableText {
    border: none;
    font-size: inherit;
    font-weight: inherit;
    text-decoration: inherit;
    max-width: 100%;
    text-align: inherit;
}

.EditableText--saving {
    position: relative;
}

.EditableText--saving .EditableText__activity {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    pointer-events: none;
}

input.EditableText, textarea.EditableText, span.EditableText:hover {
    outline: 1px dashed #000;
}

span.EditableText {
    background-color: #ffc;
    cursor: text;
    border-bottom: 1px dashed #cca;
    overflow-wrap: break-word;
}

input.EditableText.block, textarea.EditableText.block, span.EditableText.block {
    display: block;
}

input.EditableText.invalid, textarea.EditableText.invalid {
    background-color: #fdd;
}
