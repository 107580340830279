@keyframes PulsingQRCode {
    from {
        box-shadow: 0 0 30px #ed6a46;
    }
    50% {
        box-shadow: 0 0 30px #0099cc;
    }
    to {
        box-shadow: 0 0 30px #ed6a46;
    }
}
.pulsing-qr-code {
    animation: 4s linear infinite PulsingQRCode;
    border: 5px solid #fff;
}
