.ListView {
}

.ListView__item {
    /*border-bottom: 1px solid #;*/
    padding: 10px 0;
}

.ListView__item.clickable:hover {
    background-color: #fafafa;
}

.ListView__item:last-child {
    border-bottom: none;
}
